
const sendApiRequest = async (method, path, body = null) => {
  const url =`${process.env.REACT_APP_PLATFORM_API}${path}`

  const requestOptions = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    }
  };

  if (body) {
    requestOptions.body = JSON.stringify(body);
  }
  
  const data =  await fetch(url, requestOptions);
  const bodyData = await data.json();

  if ( data.ok ) { 
    return bodyData
  } else {
    //eslint-disable-next-line no-throw-literal
    throw {
      code: data.status,
      success: false,
      data: bodyData,
    }
  }
};

export const getRequest = async (path) => {
  return sendApiRequest('GET', path);
}

export const postRequest = async (path, body) => {
  return await sendApiRequest('POST', path, body);
}

export const patchRequest = async (path, body) => {
  return sendApiRequest('PATCH', path, body);
}

export const deleteRequest = async (path) => {
  return sendApiRequest('DELETE', path);
}


/**
 * @typedef {Object} PlatformOption
 * @property {Boolean} promoCodeRequired
 */

/**
 * @typedef {Object} NewUser
 * @property {string} name
 * @property {string} email
 * @property {string} password
 * @property {string} confirmPassword
 * @property {string} promoCode
 * @property {boolean} rulesAgree
 * @property {string} phone
 */

/**
 * @typedf {Object} ContactFeedback
 * @property {string} name
 * @property {string} phone
 * @property {string} email
 * @property {string} message
 */

/**
 * @typedef {Object} LoginUser
 * @property {string} login
 * @property {string} password
 */

/**
 * @typedef {Object} LoginResponse
 * @property {boolean} success
 * @property {string} token
 
 */

const API_REGISTER = '/auth/sign-up';
const API_SEND_FEEDBACK = '/feedback/landing';
const API_LOGIN = '/auth/sign-in';
const API_PLATFORM_OPTIONS = '/auth/platform-options';

/**
 * @param {NewUser} values
 */
export const registerNewUser = async (values) => {
  return await postRequest(API_REGISTER, values);
}

/**
 * @param {ContactFeedback} values
 */
export const sendFeedback = async (values) => {
  return await postRequest(API_SEND_FEEDBACK, values);
}

/**
 * @param {LoginUser} values
 * @returns {LoginResponse}
 */
export const signInUser = async (values) => {
  return await postRequest(API_LOGIN, {
    login: values.login,
    password: values.password,
  });
}

export const getPlatformOptions = async () => {
  const response = await getRequest(API_PLATFORM_OPTIONS);

  if (response.success) {
    return response.data;
  }
  return null;
}