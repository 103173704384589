import React from 'react'
import { useTranslation } from 'react-i18next'
import Visa from '../img/visa.png'
import MasterCard from '../img/MasterCard.png'
import MasterCardSecure from '../img/MasterCardSecure.png'
import Maestro from '../img/Maestro.png'
import Mir from '../img/MIR.png'
import BTC from '../img/BTC.png'
import Tither from '../img/Tither.png'
import { Link } from 'react-router-dom'
import {ActionRegisterMultiCta} from "./ActionRegisterMultiCta";


const Payment = () => {
  const {t} = useTranslation();

  return (
    <div className='flex flex-col items-center lg:px-[42px] px-[10px]'>
      <div className='font-700 text-25 lg:text-37 md:text-start text-center'>
      {t('Безопасные способы')} <span className='text-blue'>{t('пополнения и вывода')}</span>
      </div>
      <div className='font-600 md:text-22 text-[16px] mt-8 md:text-start text-center max-w-[327px] md:max-w-[743px]'>
      {t('Мы предоставляем самые низкие комиссии ны рынке для торговли, пополнения и вывода средств со счета')} 
      </div>
      <div className='flex lg:flex-row mt-20 lg:mt-47 flex-wrap justify-around'>
      <img src={Visa} alt="VISA" className='lg:mr-41 max-h-27 self-center mt-36 mx-[5px]'/>
        <img src={MasterCard} alt="MasterCard" className='lg:mx-41 max-h-49 self-center mt-36 mx-[5px]'/>
        <img src={MasterCardSecure} alt="MasterCardSecure" className='lg:mx-41 max-h-29 self-center mt-36 mx-[5px]'/>
        <img src={Maestro} alt="maestro" className='lg:mx-41 max-h-65 self-center mt-36 mx-[5px]'/>
        <img src={Mir} alt="MIR" className='lg:mx-41 max-h-31 self-center mt-36 mx-[5px]'/>
        <img src={BTC} alt="BTC" className='lg:mx-41 max-h-64 self-center mt-36 mx-[5px]'/>
        <img src={Tither} alt="Tither" className='lg:ml-41 max-h-64 self-center mt-36 mx-[5px]'/>
      </div>
      <ActionRegisterMultiCta align={'self-center'}/>
    </div>
  )
}

export default Payment