import React from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

function PhoneNumberInput({ onChange }) {
  const handlePhoneNumberChange = (phone) => {
    onChange(phone);
  };

  return (
    <PhoneInput
      containerClassName="intl-tel-input"
      inputClassName="form-control"
      defaultCountry="ru"
      onChange={handlePhoneNumberChange}
    />
  );
}

export default PhoneNumberInput;