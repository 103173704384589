import React from 'react'
import { useTranslation } from 'react-i18next';
import Advantages1 from '../img/Advantages1.png'
import Advantages2 from '../img/Advantages2.png'
import Advantages3 from '../img/Advantages3.png'
import Advantages4 from '../img/Advantages4.png'
import Advantages5 from '../img/Advantages5.png'
import AdvantagesBg from '../img/AdvantagesBg.png'

const AboutAdvantages = () => {
  const {t} = useTranslation();

  return (
    <div className='flex flex-col lg:pb-118 lg:pt-73 bg-no-repeat items-center text-center' style={{ backgroundImage: `url(${AdvantagesBg})`, backgroundPosition:'0px -300px'}}>
      <div className='flex flex-col lg:px-42 px-[10px] bg-no-repeat items-center text-center w-fit'>
      <div className='text-37 font-700'>{t('Немного')} <span className='text-blue'>{t('цифр')}</span></div>
      <div className='text-22 font-600 mt-17 max-w-890'>{t('Мы вкладываем наш богатый опыт, навыки и усилия в счастье наших клиентов. Вот почему так много трейдеров предпочитают продолжать выигрывать с Assets Management Solutions.')}</div>
        <div className='flex flex-wrap max-w-fit w-full mt-63 lg:text-start text-22 font-600 justify-around items-center'>
          <div className='flex flex-col items-center lg:items-start min-w-[225px] mt-12'>
            <div className='p-25 border border-grey-400 shadow-md rounded-15 max-w-144 max-h-144 bg-white'>
              <img src={Advantages1} alt="Advantages1"/>
            </div>
            <div className='md:mt-33 mt-[10px] mb-[15px]'><span className='font-700 text-39'>10</span> {t('лет')}<br/>{t('на рынке')}</div>
          </div>
          <div className='flex flex-col items-center lg:items-start min-w-[225px] mt-12'>
            <div className='p-25 border border-grey-400 shadow-md rounded-15 max-w-144 max-h-144 bg-white'>
              <img src={Advantages2} alt="Advantages2"/>
            </div>
            <div className='md:mt-33 mt-[10px] mb-[15px]'><span className='font-700 text-39'>10</span> {t('офисов')}<br/>{t('по всему миру')}</div>
          </div>
          <div className='flex flex-col items-center lg:items-start min-w-[225px] mt-12'>
            <div className='p-25 border border-grey-400 shadow-md rounded-15 max-w-144 max-h-144 bg-white'>
              <img src={Advantages3} alt="Advantages3"/>
            </div>
            <div className='md:mt-33 mt-[10px] mb-[15px]'><span className='font-700 text-39'>260 000 +</span><br/>{t('активных клиентов')}</div>
          </div>
          <div className='flex flex-col items-center lg:items-start min-w-[225px] mt-12'>
            <div className='p-25 border border-grey-400 shadow-md rounded-15 max-w-144 max-h-144 bg-white'>
              <img src={Advantages4} alt="Advantages4"/>
            </div>
            <div className='md:mt-33 mt-[10px] mb-[15px]'><span className='font-700 text-39'>100 +</span><br/>{t('торговых пар')}</div>
          </div>
          <div className='flex flex-col items-center lg:items-start min-w-[225px] mt-12'>
            <div className='p-25 border border-grey-400 shadow-md rounded-15 max-w-144 max-h-144 bg-white' style={{minHeight:'144px'}}>
              <img src={Advantages5} alt="Advantages5"/>
            </div>
            <div className='md:mt-33 mt-[10px] mb-[15px]'><span className='font-700 text-39'>80 %</span><br/>{t('положительных отзывов')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutAdvantages