import React from 'react'
import {useTranslation} from 'react-i18next';
import FirstStepBg from '../img/FirstStepBg.png'
import FirstStepPhones from '../img/FirstStepPhones.png'
import {Link} from 'react-router-dom';
import {ActionRegisterMultiCta} from "./ActionRegisterMultiCta";

const FirstStep = () => {
    const {t} = useTranslation();

    return (
        <div className='flex flex-col lg:flex-row justify-evenly xl:justify-center lg:px-42 px-[10px] lg:pb-21 pt-24 bg-auto bg-bottom bg-no-repeat' style={{ backgroundImage: `url(${FirstStepBg})`}}>
            <img src={FirstStepPhones} alt="phones" className='xl:max-w-[524px] lg:max-w-[424px] max-w-[350px] floating-image self-center'/>
            <div className='flex flex-col self-center lg:mt-47 xl:ml-47'>
                <div className='text-white text-[38px] lg:text-48 font-700 self-center lg:self-start lg:mt-[25px]'>{t('Сделай первый шаг')}</div>
                <div className='text-white text-[56px] lg:text-61 font-700 lg:mt-12 mt-[6px] self-start'>{t('К УСПЕХУ')}</div>
                <ActionRegisterMultiCta align={'self-center lg:self-start'} bg={'bg-white'} color={'text-blue'} isStaticColor={true}/>
            </div>
        </div>
    )
}

export default FirstStep