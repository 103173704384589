import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

function MapPosition() {
  // const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const apiKey = 'AIzaSyAjNumG5LYLWc-20R9wPpOtWkd8CvcjPvo';

  
  const companyLocationLat = process.env.REACT_APP_COMPANY_LOCATION_LAT;
  const companyLocationLng = process.env.REACT_APP_COMPANY_LOCATION_LNG;
  
  const location = {
    lat: companyLocationLat,
    lng: companyLocationLng,
  };
 
  return (
    <div style={{ height: '278px', width: '100%' }} className='mt-25'>
      <LoadScript googleMapsApiKey={apiKey}>
        <GoogleMap
          center={location}
          zoom={15}
          mapContainerStyle={{ height: '100%', width: '100%' }}
        >
          <Marker position={location} />
        </GoogleMap>
      </LoadScript>
    </div>
  );
}

export default MapPosition;