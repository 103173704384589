import React from 'react'; 
import IntroImg from '../img/IntroImg.png'
import IntroImgBg from '../img/IntroImgBg.png'
import Intro1 from '../img/intro1.png'
import Intro2 from '../img/intro2.png'
import Intro3 from '../img/intro3.png'
import IntroBg from '../img/introBg.png'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {PATH_REGISTER} from "../routing";
import {ActionRegisterMultiCta} from "./ActionRegisterMultiCta";


const Intro = () => {
  const {t} = useTranslation();

  return (
    <div className='flex flex-col lg:flex-row items-center justify-evenly mt-[100px] lg:px-42 px-[10px] lg:pl-133 lg:pr-42 bg-contain bg-top-left bg-no-repeat'  style={{ backgroundImage: `url(${IntroBg})`}}>
      <div className='pr-0  flex flex-col'>
        <div className='flex flex-col max-w-463 self-center'>
          <div className='font-700 text-28 lg:text-48 text-center md:text-center lg:text-right'>{t('Делаем Ваши торги')}</div>
          <div className='text-blue font-700 text-48 lg:text-61 text-center lg:text-right leading-11 mb-[10px]'>{t('УДОБНЫМИ')}</div>
          <div className='font-700 text-[17px] text-center md:text-center lg:text-end'>{t('Совершенствуйте торговые навыки и открывайте для себя широкий спектр финансовых возможностей')}</div>
          <ActionRegisterMultiCta align={' self-center lg:self-end'}/>
        </div>
        <div className='flex flex-col lg:flex-row items-center text-center'>
          <div className='flex flex-col items-center  py-25 px-20 max-w-260'>
            <img src={Intro1} alt="intro-img-1" />
            <div className='font-700 size-15 my-5.5 text-darkBlue'>{t('ВЫБОР АКТИВОВ')}</div>
            <div className='font-600 size-12 text-greyBlue'>{t('Самый большой выбор активов на рынке')}</div>
          </div>
          <div className='flex flex-col items-center  py-25 px-20 max-w-260'>
            <img src={Intro2} alt="intro-img-2" />
            <div className='font-700 size-15 my-5.5 text-darkBlue'>{t('ГАРАНТИИ')}</div>
            <div className='font-600 size-12 text-greyBlue'>{t('Фонд гарантированных выплат до 500 000$')}</div>
          </div>
          <div className='flex flex-col items-center  py-25 px-20 max-w-260'>
            <img src={Intro3} alt="intro-img-3" />
            <div className='font-700 size-15 my-5.5 text-darkBlue'>{t('БОНУСЫ')}</div>
            <div className='font-600 size-12 text-greyBlue'>{t('Бонусы к вашему депозиту до 200%')}</div>
          </div>
        </div>
      </div>
      <div className='max-w-380 lg:max-w-612 bg-no-repeat bg-center bg-contain' style={{backgroundImage: `url(${IntroImgBg})`}}>
        <img
          src={IntroImg}
          alt="intro-img"
          className="floating-image"
        />
      </div>
    </div>
  )
}

export default Intro