import React from 'react'
import AboutMissionBg from '../img/AboutMissionBg.png'
import AboutMissionPhones from '../img/AboutMission.png'
import { useTranslation } from 'react-i18next';

const AboutMission = () => {
  const {t} = useTranslation();

  return (
    <div className='flex flex-col lg:flex-row items-center justify-center lg:px-42 px-[10px] lg:pl-133 lg:pr-42 bg-contain bg-right bg-no-repeat'  style={{ backgroundImage: `url(${AboutMissionBg})`, backgroundPosition:'right 44px'}}>
      <img src={AboutMissionPhones} alt="our-mission" className='floating-image mt-[20px] lg:mt-0  xl:max-w-[524px] lg:max-w-[424px] '/>
      <div className='max-w-463 text-center lg:text-left py-27 lg:px-34 bg-white rounded-15'>
        <div className='text-37 font-700'>{t('Наша миссия')}</div>
        <div className='text-22 font-600 text-gray-600'>{t('C 2012 года мы поставили перед собой цель стать путеводной звездой на финансовых рынках для трейдеров с любым опытом и предоставить им все лучшие возможности для процветания.')}<br/><br/>
        {t('Мы придерживаемся этой цели, предлагая самые выгодные торговые условия на рынке, фонд гарантированных выплат, а так же бонусы для наших клиентов.')}</div>
      </div>
    </div>
  )
}

export default AboutMission