import { create } from 'zustand'

const DEFAULT_PLATFORM_OPTIONS = {
    promoCodeRequired: false,
    registrationAllowed: false,
    signedIn: false,
    loaded: false,
}

export const usePlatformOptionsStore = create((set) => ({
    platformOptions: DEFAULT_PLATFORM_OPTIONS,
    setOptions: (options) => set({
        platformOptions: options,
    }),
}))