import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import * as Yup from 'yup';
import Header from '../components/Header';
import PhoneNumberInput from '../components/PhoneNumberInput';
import eyeIcon from '../img/eyeIcon.svg';
import hideIcon from '../img/hideIcon.svg';
import {registerNewUser, signInUser} from '../_api/request';
import {usePlatformOptions, useRegisterPageRouting} from '../hooks/use-platform-options';
import toast from 'react-hot-toast';
import {usePlatformOptionsStore} from "../stores/platform-options";
import {PATH_LOG_IN} from "../routing";

/* eslint-disable react-hooks/exhaustive-deps */

const Registration = () => {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const {platformOptions} = usePlatformOptionsStore();
  useRegisterPageRouting(platformOptions);

  const schema = Yup.object({
    name: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').matches(/^\s*[\S]+(\s+[\S]+)+\s*$/gms,'Please enter your full name.').required('Required'),
    email: Yup.string().min(3).max(50).email().required('Email Required'),
    password: Yup.string().min(3).max(50)
      .required('Required'),
    confirmPassword: Yup.string().min(3).max(50)
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
    promoCode: Yup.string().when([], {
      is: () => platformOptions?.promoCodeRequired,
      then: (schema) => schema.min(3).max(50).required('Promo code required'),
    }),
    rulesAgree: Yup.boolean()
      .oneOf([true], 'Must Accept Terms and Conditions'),
    phone: Yup.string().min(9, 'Too Short!').max(20, 'Too Long!').required('Phone Required')
  });
  

  const formik = useFormik({
    initialValues: {
      name: 'john doe ',
      email: 'test@test.com',
      password: 'abcabc',
      confirmPassword: 'abcabc',
      promoCode: '',
      rulesAgree: true,
      phone: '',
    },
    validationSchema: schema,
    onSubmit: async values => {
      try {
        const result = await registerNewUser(values);

        if (result.success) {
          const loginResult = await signInUser({
            login: values.email,
            password: values.password,
          });
          if (loginResult.success) {
            window.location.href = '/trading';
          }
        } else {
          toast.error(t('Ошибка регистрации'));
        }
      } catch (e) {
        console.log(e);
      }
    },
    validateOnMount: true,
    enableReinitialize: true,
  });

  const { values } = formik;

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    formik.validateForm();
  }, [platformOptions]);

  return (
    <div className='flex flex-col items-center'>
      <Header></Header>
      <form className='flex flex-col items-center pb-20 pt-[104px] md:pt-[124px] max-w-[293px]'>
        <span className='text-30 font-700'>{t('Регистрация')}</span>
        <input
          type="text"
          className='border border-gray-200 rounded-10 mt-12 lg:mt-40 min-h-50 min-w-293 p-13'
          required
          placeholder={t('ФИО')}
          value={values.name}
          onChange={(e) => { formik.setFieldValue('name', e.target.value) }}
        />
        <input
          type="text"
          className='border border-gray-200 rounded-10 mt-12  min-h-50 min-w-293 p-13'
          required
          placeholder={t('Эл. почта')}
          value={values.email}
          onChange={(e) => { formik.setFieldValue('email', e.target.value) }}
        />
        <PhoneNumberInput onChange={(value) => formik.setFieldValue('phone', value)} />
        <div className='relative'>
          <input
            type={!passwordVisible ? 'text' : 'password'}
            className='border border-gray-200 rounded-10 mt-12 min-h-50 min-w-293 p-13 pr-8'
            required
            placeholder={t('Пароль')}
            value={values.password}
            onChange={(e) => { formik.setFieldValue('password', e.target.value) }}
          />
          <img
            src={!passwordVisible ? hideIcon : eyeIcon}
            alt={!passwordVisible ? "hide-icon" : "eye-icon"}
            className='absolute top-1/2 transform -translate-y-1/4 right-4 cursor-pointer w-[32px] h-[30px]'
            onClick={togglePasswordVisibility}

          />
        </div>
        <div className='relative'>
          <input
            type={!passwordVisible ? 'text' : 'password'}
            className='border border-gray-200 rounded-10 mt-12 min-h-50 min-w-293 p-13 pr-8'
            required
            placeholder={t('Повторите пароль')}
            value={values.confirmPassword}
            onChange={(e) => { formik.setFieldValue('confirmPassword', e.target.value) }}
          />
          <img
            src={!passwordVisible ? hideIcon : eyeIcon}
            alt={!passwordVisible ? "hide-icon" : "eye-icon"}
            className='absolute top-1/2 transform -translate-y-1/4 right-4 cursor-pointer w-[32px] h-[30px]'
            onClick={togglePasswordVisibility}

          />
        </div>
        <div className='mt-12 text-12 text-left min-w-293'>
          {
            platformOptions?.promoCodeRequired ? t('Обязательно') : t('Не обязательно')
          }
        </div>
        <input
          type="text"
          className='border border-gray-200 rounded-10 min-h-50 min-w-293 px-13'
          placeholder={t('Промокод')}
          value={values.promoCode}
          onChange={(e) => { formik.setFieldValue('promoCode', e.target.value) }}
        />
        <div className='flex flex-row mt-20 max-w-289'>
          <input
            type="checkbox"
            className='w-16 h-16 mt-3 cursor-pointer'
            checked={values.rulesAgree}
            onChange={(e) => formik.setFieldValue('rulesAgree', e.target.checked)}
          />
          <div className='ml-19 text-12 font-400'>
            {t('Я принимаю')} <a href="." className=' underline'>{t('Условия соглашения')}</a> {t('и')} <a href="." className=' underline'>{t('Политики')}
              <br /> {t('конфиденциальности')}</a> {t('и подтверждаю, что я достиг совершеннолетия.')}
          </div>
        </div>
        <button
          type="submit"
          onClick={formik.handleSubmit}
          className={`font-600 text-19 px-16 py-14 mt-19 rounded-10 
          bg-gradient-to-r from-gradientBlue1 to-gradientBlue2 text-white 
          self-center cursor-pointer min-w-260 ${(!formik.isValid) ? 'opacity-50 pointer-events-none' : 'hoverScale hoverEffectButton'}`}
          disabled={!formik.isValid}
        >
          {t('Зарегистрироваться')}
        </button>
        <span className='text-16 font-600 self-center mt-32 lg:mt-64'>{t('Уже есть аккаунт?')} <Link to={PATH_LOG_IN} className='font-600 underline text-18'>{t('Войти')}</Link></span>
      </form>
    </div>
  )
}

export default Registration;