import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import usdToRubLogo from '../img/UsdRub.png';
import usdToEurLogo from '../img/UsdEur.png';
import btcLogo from '../img/btc.jpg';
import ethLogo from '../img/eth.png';
import dogeLogo from '../img/doge.png';
import GoldLogo from '../img/gold.jpg';
import OilLogo from '../img/oil.jpg';
import WoodLogo from '../img/wood.jpg';
import OrangeLogo from '../img/orange.jpg';
import GasLogo from '../img/gas.jpg';

const RunningLine = () => {
  const [btc, setBtc] = useState('');
  const [eth, setEth] = useState('');
  const [doge, setDoge] = useState('');
  const [usdToEur, setUsdToEur] = useState('');
  const [usdToRub, setUsdToRub] = useState('');

  useEffect(() => {
    async function getCryptoPrices() {
      try {
        // Отримання цін для Bitcoin, Ethereum та Dogecoin в USD з CoinGecko
        const response = await fetch(
          'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,dogecoin&vs_currencies=usd'
        );
      
        if (!response.ok) {
          throw new Error('Помилка при виконанні запиту до API CoinGecko');
        }
      
        const data = await response.json();
      
        const bitcoinPrice = data.bitcoin.usd;
        setBtc(bitcoinPrice);
        const ethereumPrice = data.ethereum.usd;
        setEth(ethereumPrice);
        const dogecoinPrice = data.dogecoin.usd;
        setDoge(dogecoinPrice);
      
        // Отримання курсу USD/EUR з CoinGecko
        const usdToEurResponse = await fetch(
          'https://api.coingecko.com/api/v3/simple/price?ids=usd&vs_currencies=eur'
        );
        const usdToEurData = await usdToEurResponse.json();
        const usdToEurRate = usdToEurData.usd.eur;
        setUsdToEur(usdToEurRate);
      
        // Отримання курсу USD/RUB з CoinGecko
        const usdToRubResponse = await fetch(
          'https://api.coingecko.com/api/v3/simple/price?ids=usd&vs_currencies=rub'
        );
        const usdToRubData = await usdToRubResponse.json();
        const usdToRubRate = usdToRubData.usd.rub;
        setUsdToRub(usdToRubRate);
      } catch (error) {
        console.error('Помилка під час отримання цін:', error);
      }
    }

    /**
     * @todo uncomment
     */
    // getCryptoPrices();
  }, []);

  return (
    <Marquee loop={0} className='w-full flex flex-row mt-[15px]' autoFill={true}>
      <span className="font-bold flex flex-row px-[15px] items-center min-h-40 border-grey border-solid border-r"><img src={usdToEurLogo} alt="icon" className='mr-9' style={{maxWidth:'40px'}}/>USD/EUR{usdToEur}</span>
      <span className="font-bold flex flex-row px-[15px] items-center min-h-40 border-grey border-solid border-r"><img src={usdToRubLogo} alt="icon" className='mr-9' style={{maxWidth:'40px'}}/>USD/RUB{usdToRub}</span>
      <span className="font-bold flex flex-row px-[15px] items-center min-h-40 border-grey border-solid border-r"><img src={btcLogo} alt="icon" className='mr-9' style={{maxWidth:'40px'}}/>Bitcoin{btc}</span>
      <span className="font-bold flex flex-row px-[15px] items-center min-h-40 border-grey border-solid border-r"><img src={ethLogo} alt="icon" className='mr-9' style={{maxWidth:'40px'}}/>Ethereum{eth}</span>
      <span className="font-bold flex flex-row px-[15px] items-center min-h-40 border-grey border-solid border-r"><img src={dogeLogo} alt="icon" className='mr-9' style={{maxWidth:'30px'}}/>Dogecoin{doge}</span>
      <span className="font-bold flex flex-row px-[15px] items-center min-h-40 border-grey border-solid border-r"><img src={GoldLogo} alt="icon" className='mr-9' style={{maxWidth:'30px'}}/>Gold</span>
      <span className="font-bold flex flex-row px-[15px] items-center min-h-40 border-grey border-solid border-r"><img src={OilLogo} alt="icon" className='mr-9' style={{maxWidth:'30px'}}/>Oil</span>
      <span className="font-bold flex flex-row px-[15px] items-center min-h-40 border-grey border-solid border-r"><img src={WoodLogo} alt="icon" className='mr-9' style={{maxWidth:'30px'}}/>Wood</span>
      <span className="font-bold flex flex-row px-[15px] items-center min-h-40 border-grey border-solid border-r"><img src={GasLogo} alt="icon" className='mr-9' style={{maxWidth:'30px'}}/>Gas</span>
      <span className="font-bold flex flex-row px-[15px] items-center min-h-40 border-grey border-solid border-r"><img src={OrangeLogo} alt="icon" className='mr-9' style={{maxWidth:'30px'}}/>Oranges</span>
    </Marquee>
  );
};

export default RunningLine;