import React from 'react';
import footerFinancial from '../img/footerFinancial.png';
import footerVerify from '../img/footerVerify.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();

  const address = process.env.REACT_APP_FOOTER_TEXT;
  const privacyPolicyPDF = process.env.REACT_APP_PRIVACY_POLICY_PDF;
  const termsConditionsPDF = process.env.REACT_APP_TERMS_CONDITIONS_PDF;
  const kycPolicyPDF = process.env.REACT_APP_KYC_POLICY_PDF;
  const riskDisclosurePDF = process.env.REACT_APP_RISK_DISCLOSURE_PDF;

  return (
    <div className='flex flex-col pt-42 pb-[10px] lg:mt-30 text-gray-600'>
      <div className='flex flex-col lg:flex-row justify-start lg:px-42 px-[10px] lg:text-start font-600 text-12'>
        <div className='flex flex-row max-w-380 lg:mx-22 my-22 items-center'>
          <img src={footerVerify} alt="logo-Verify" className='mr-8' style={{width:'70px', height:'54px'}}/>
          <div>{t("Качество исполнения Assets Management Solutions подтверждено VMT и соответствует лучшим стандартам исполнения.")}</div>
        </div>
        <div className='flex flex-row max-w-380 lg:mx-22 items-center'>
          <img src={footerFinancial} alt="logo-Financial" className='mr-8' style={{width:'129px', height:'68px'}}/>
          <div>{t("Интересы клиентов AMS защищает Фонд Финансовой Комиссии на сумму до 20 000 евро за одно требование.")}</div>
        </div>
      </div>
      <div className='flex flex-col md:flex-row justify-between font-600 text-[11px] md:text-[13px] lg:px-42 px-[10px] mt-20'>
        <div className='flex flex-row flex-wrap md:flex-nowrap justify-around mb-8'>
          <a href={privacyPolicyPDF} className='mx-12 mt-20 hoverScale' download>{t("Политика конфиденциальности")}</a>
          <a href={termsConditionsPDF} className='mx-12 mt-20 hoverScale' download>{t("Условия и положения")}</a>
          <Link to='/About' className='mx-12 mt-20 hoverScale'>{t("Условия сотрудничества")}</Link>
          <a href={kycPolicyPDF} className='mx-12 mt-20 hoverScale' download>{t("Политика ПОД")}</a>
          <a href={riskDisclosurePDF} className='mx-12 mt-20 hoverScale' download>{t("Раскрытие рисков")}</a>
        </div>
      </div>
      <div className='font-600 text-10 lg:px-42 px-[10px] mt-20'>
      {t(address)}
      </div>
    </div>
  );
};

export default Footer;
