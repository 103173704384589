import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {PATH_LOG_IN, PATH_REGISTER, PATH_TRADING} from "../routing";
import {usePlatformOptionsStore} from "../stores/platform-options";
// import oldImportLogo from '../img/logo/logo-white.svg'

const Header = () => {

  const {t} = useTranslation();
  const logoUrl = process.env.REACT_APP_LOGO_URL;

    const {platformOptions} = usePlatformOptionsStore();

    let prevScrollPos = window.scrollY;

  window.onscroll = function() {
      var currentScrollPos = window.scrollY;
      if (prevScrollPos > currentScrollPos) {
          // Show header when user scroll into top
          document.querySelector(".header").classList.add("active");
      } else {
          // Hide header when user scroll into bottom
          document.querySelector(".header").classList.remove("active");
      }
      prevScrollPos = currentScrollPos;
  };

  const registerCtaVisible = platformOptions.registrationAllowed && ! platformOptions.signedIn;

  return (
    <div className='flex justify-between py-27 lg:pl-133 lg:pr-42 text-18 font-600 header active z-50' style={{height:"90 px"}}>
      <div className='flex flex-row items-center jus'>
        { logoUrl ? 
          <Link to='/'><img src={logoUrl} alt="logo" className='ml-18 hoverScale max-w-[120px]' /></Link>
        : null
        }
        <Link to='/' className='mx-18 hidden lg:block hoverScale'>{t('Главная')}</Link>
        <Link to='/About' className='mx-18 hidden lg:block hoverScale'>{t('О нас')}</Link>
      </div>
      <div className='flex flex-row items-center px-18'>
        <Link to='/About' className='lg:hidden block hoverScale text-[18px]'>{t('О нас')}</Link>
        
        
          { registerCtaVisible ?
            <>
              <Link to={PATH_LOG_IN} className='mx-18 lg:block hoverScale'>{t('Войти')}</Link>
              <Link to={PATH_REGISTER} className='mx-18 lg:block hoverScale'>{t('Зарегистрироваться')}</Link>
            </> : <Link to={PATH_TRADING}
            className={'font-600 px-[10px] ml-[20px] py-[7px] rounded-10 max-w-200 flex text-center justify-center text-[16px] cursor-pointer highlight-button hoverScale bg-gradient-to-r from-gradientBlue1 to-gradientBlue2 text-white'}>
                {t('Торговля')}
            </Link>
          }
      </div> 
    </div>
  )
}

export default Header