import React from 'react'
import ActivesPhones from '../img/ActivesPhones.png'
import ActivesChart from '../img/ActivesChart.png'
import ActivesBg1 from '../img/ActivesBg1.png'
import ActivesBg2 from '../img/ActivesBg2.png'
import ActivesBg from '../img/ActivesBg.png'
import { useTranslation } from 'react-i18next'

const Actives = () => {
  const {t} = useTranslation();
  const leftBlock = 'flex flex-col items-left  p-[15px] md:p-26 max-w-[160px] md:max-w-260 min-h-[132px] text-start mt-18 md:mt-30 mr-22 bg-white rounded-15 border border-solid border-gray-300 lg:border-white shadow-md';
  const rightBlock = 'flex flex-col items-left  p-[15px] md:p-26 max-w-[160px] md:max-w-260 min-h-[132px] text-start mt-18 md:mr-22 bg-white rounded-15 border border-solid border-gray-300 lg:border-white shadow-md';
  const blockText ='font-600 text-12 text-greyBlue md:min-w-200';


  return (
    <div className='flex flex-col xl:flex-row justify-center items-center lg:px-42 px-[10px] lg:pl-133 lg:pr-42 bg-contain bg-right-bottom bg-no-repeat' style={{ backgroundImage: `url(${ActivesBg})`}}>
      <img src={ActivesPhones} alt="phones" className='mt-[20px] xl:max-w-[524px] lg:max-w-[424px] h-fit bg-center bg-no-repeat bg-contain floating-image' style={{ backgroundImage: `url(${ActivesBg1})`}}/>
      <div className='flex flex-col self-center md:flex-row bg-no-repeat bg-contain bg-left lg:pb-64 lg:pl-64 pt-20' style={{ backgroundImage: `url(${ActivesBg2})`}}>
        <div className='flex flex-col items-center'>
          <div className='flex flex-col items-left  p-26 max-w-260 text-start mt-30 md:mr-22 bg-white rounded-15 border border-solid border-gray-300 lg:border-white shadow-md'>
            <span className='flex flex-row font-700 text-15 pb-18 items-center'><img src={ActivesChart} alt="ActivesChart" className='mr-7'/>{t('ФОРЕКС')}</span>
            <div className='font-600 size-12 text-greyBlue min-w-200'>{t('Доступ к 50+ валютным парам')}</div>
          </div>
          <div className='flex flex-col items-left  p-26 max-w-260 text-start mt-18 md:mr-22 bg-white rounded-15 border border-solid border-gray-300 lg:border-white shadow-md'>
            <span className='flex flex-row font-700 text-15 pb-18 items-center'><img src={ActivesChart} alt="ActivesChart" className='mr-7'/>{t('СЫРЬЕ')}</span>
            <div className='font-600 size-12 text-greyBlue min-w-200'>{t('Более, чем 10 товарно сырьевых активов')}</div>
          </div>
          <div className='flex flex-col items-left  p-26 max-w-260 text-start mt-18 md:mr-22 bg-white rounded-15 border border-solid border-gray-300 lg:border-white shadow-md'>
            <span className='flex flex-row font-700 text-15 pb-18 items-center'><img src={ActivesChart} alt="ActivesChart" className='mr-7'/>{t('КРИПТО')}</span>
            <div className='font-600 size-12 text-greyBlue min-w-200'>{t('Доступ к 75+ криптовалютным парам')}</div>
          </div>
        </div>
        <div className='flex flex-col items-center'>
          <div className='flex flex-col items-left  p-26 max-w-260 text-start mt-18 md:mr-22 bg-white rounded-15 border border-solid border-gray-300 lg:border-white shadow-md'>
            <span className='flex flex-row font-700 text-15 pb-18 items-center'><img src={ActivesChart} alt="ActivesChart" className='mr-7'/>{t('МЕТАЛЛЫ')}</span>
            <div className='font-600 size-12 text-greyBlue min-w-200'>{t('Золото, серебро и другие драгоценные металлы')}</div>
          </div>
          <div className='flex flex-col items-left  p-26 max-w-260 text-start mt-18 md:mr-22 bg-white rounded-15 border border-solid border-gray-300 lg:border-white shadow-md'>
            <span className='flex flex-row font-700 text-15 pb-18 items-center'><img src={ActivesChart} alt="ActivesChart" className='mr-7'/>{t('АКЦИИ')}</span>
            <div className='font-600 size-12 text-greyBlue min-w-200'>{t('Доступ к 100+ акциям мировых брендов')}</div>
          </div>
          <div className='flex flex-col items-left  p-26 max-w-260 text-start mt-18 md:mr-22 bg-white rounded-15 border border-solid border-gray-300 lg:border-white shadow-md'>
            <span className='flex flex-row font-700 text-15 pb-18 items-center'><img src={ActivesChart} alt="ActivesChart" className='mr-7'/>{t('ИНДЕКСЫ')}</span>
            <div className='font-600 size-12 text-greyBlue min-w-200'>{t('Доступ к 10+ мировым индексам')}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Actives