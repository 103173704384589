/**
 * @typedef {Object} PlatformOptionsForLanding
 * @property {boolean} promoCodeRequired - Indicates whether a promo code is required.
 * @property {boolean} registrationAllowed
 * @property {boolean} signedIn
 */

import {useEffect, useState} from "react";
import {getPlatformOptions} from "../_api/request";
import {PATH_HOME, PATH_LOG_IN} from "../routing";
import {usePlatformOptionsStore} from "../stores/platform-options";

/**
 * @return PlatformOptionsForLanding | null
 */
export const usePlatformOptions = () => {
    const {platformOptions, setOptions, setLoading} = usePlatformOptionsStore();

    useEffect(() => {
        getPlatformOptions().then((response) => {
            if (response) {
                setOptions({
                    ...response,
                    loaded: true,
                });
            }
        });
    }, []);

    return platformOptions;
}

/**
 * @property options {PlatformOptionsForLanding}
 */

export const useRegisterPageRouting = (options) => {

    useEffect(() => {
        if (options.loaded) {
            if (options.signedIn) {
                window.location.href = PATH_HOME;
                return;
            } else if (!options.registrationAllowed) {
                window.location.href = PATH_LOG_IN;
                return;
            }
        }
    }, [options]);
}

/**
 * @property options {PlatformOptionsForLanding}
 */

export const useLoginPageRouting = (options) => {

    useEffect(() => {
        if (options.loaded) {
            if (options.signedIn) {
                window.location.href = PATH_HOME;
                return;
            }
        }
    }, [options]);
}