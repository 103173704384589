import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {RouterProvider} from 'react-router';
import {createBrowserRouter} from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Registration from './pages/Registration';
import LogIn from './pages/Login';
import {Toaster} from 'react-hot-toast';
import {PATH_ABOUT, PATH_HOME, PATH_LOG_IN, PATH_REGISTER} from "./routing";
import {usePlatformOptions} from "./hooks/use-platform-options";

const router = createBrowserRouter([
  {
    path: PATH_HOME,
    element: <Home />,
  },
  {
    path: PATH_ABOUT,
    element: <About />,
  },
  {
    path: PATH_REGISTER,
    element: <Registration />,
  },
  {
    path: PATH_LOG_IN,
    element: <LogIn />,
  },
  // {
  //   path: "/ForgotPassword",
  //   element: <ForgotPas />,
  // }
])

const RootComponent = () => {
  usePlatformOptions();

  return (
      <>
        <Toaster
            position="top-center"
            reverseOrder={false}
        />
        <RouterProvider router={router} />
      </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RootComponent/>
  </React.StrictMode>
);

